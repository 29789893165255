import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["month", "day", "year", "value"]
  static values = { initialDate: String }

  connect() {
    this.populateMonths()
    this.populateYears()
    this.updateDays()
    if (this.initialDateValue) {
      this.setInitialDate(this.initialDateValue)
    }
  }

  populateMonths() {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ]
    months.forEach((month, index) => {
      this.monthTarget.insertAdjacentHTML('beforeend', `<option value="${index + 1}">${month}</option>`)
    })
  }

  populateYears() {
    const currentYear = new Date().getFullYear()
    for (let i = currentYear; i >= currentYear - 100; i--) {
      this.yearTarget.insertAdjacentHTML('beforeend', `<option value="${i}">${i}</option>`)
    }
  }

  onMonthChange() {
    this.updateDays();
    this.updateHiddenField();
  }

  onDayChange() {
    this.updateHiddenField();
  }

  onYearChange() {
    this.updateDays();
    this.updateHiddenField();
  }

  updateDays() {
    const selectedMonth = parseInt(this.monthTarget.value)
    const selectedYear = parseInt(this.yearTarget.value) || new Date().getFullYear()
    const selectedDay = parseInt(this.dayTarget.value)

    let daysInMonth = 31 // Default to 31 days if no month is selected

    if (selectedMonth) {
      daysInMonth = this.getDaysInMonth(selectedMonth, selectedYear)
    }

    // Clear existing options
    this.dayTarget.innerHTML = '<option value="">Day</option>'

    // Populate days
    for (let i = 1; i <= daysInMonth; i++) {
      this.dayTarget.insertAdjacentHTML('beforeend', `<option value="${i}">${i}</option>`)
    }

    // Restore previous selection if valid, otherwise reset to empty
    if (selectedDay && selectedDay <= daysInMonth) {
      this.dayTarget.value = selectedDay
    } else {
      this.dayTarget.value = ''
    }
  }

  updateHiddenField() {
    const day = this.dayTarget.value
    const month = this.monthTarget.value
    const year = this.yearTarget.value
    if (year && month && day) {
      const paddedMonth = month.padStart(2, '0')
      const paddedDay = day.padStart(2, '0')
      this.valueTarget.value = `${year}-${paddedMonth}-${paddedDay}`
    } else {
      this.valueTarget.value = ''
    }
  }

  updateSelects() {
    const dateString = this.valueTarget.value;
    if (dateString) {
      const [year, month, day] = dateString.split('-').map(Number);
      this.yearTarget.value = year;
      this.monthTarget.value = month;
      this.dayTarget.value = day;
      this.updateDays()
    } else {
      this.yearTarget.value = '';
      this.monthTarget.value = '';
      this.dayTarget.value = '';
    }
  }

  setInitialDate(dateString) {
    this.valueTarget.value = dateString
    this.updateSelects()
  }

  getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate()
  }

  isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)
  }
}
