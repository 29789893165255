import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu'];

  connect() {
    this.onClickHandler = this.onClick.bind(this);
    document.addEventListener('click', this.onClickHandler);
  }

  disconnect() {
    document.removeEventListener('click', this.onClickHandler)
  }

  toggle(event) {
    event.stopPropagation()
    this.menuTarget.classList.toggle('hidden');
  }

  onClick(event) {
    if (!this.element.contains(event.target)) {
      this.menuTarget.classList.add('hidden');
    }
  }
}
